// Script from https://github.com/EduardoVa/vue-google-identity-login-btn/blob/main/index.js
import Vue from 'vue';

export default Vue.directive('google-identity-login-btn', {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el, binding, vnode: any) {
    function checkComponentMethods() {
      if (!el.id)
        throw new Error(
          'The google sign in container requires an "id" attribute'
        );

      if (!vnode.context.onGoogleAuthSuccess)
        throw new Error(
          'The method onGoogleAuthSuccess must be defined on the component'
        );
    }

    checkComponentMethods();

    const { clientId } = binding.value;

    const googleBtnConfigs = {
      type: binding.value.type || 'standard',
      shape: binding.value.shape || 'rectangular',
      theme: binding.value.theme || 'outline',
      text: binding.value.text || 'continue_with',
      size: binding.value.size || 'large',
      locale: binding.value.locale || 'es-419',
      logo_alignment: binding.value.logo_alignment || 'center',
      width: binding.value.width || '300',
    };

    const googleLoginButtonId = el.id;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function OnSuccess(resp: any) {
      vnode.context.onGoogleAuthSuccess(resp.credential);
    }

    function InitGoogleButton() {
      // @ts-expect-error: google instance from load
      google.accounts.id.initialize({
        client_id: clientId,
        // ensure smooth login session
        auto_select: true,
        callback: OnSuccess,
      });
      // @ts-expect-error: google instance from load
      google.accounts.id.prompt();

      // @ts-expect-error: google instance from load
      google.accounts.id.renderButton(
        document.getElementById(googleLoginButtonId),
        googleBtnConfigs
      );
    }

    const googleSignInAPI = document.createElement('script');
    googleSignInAPI.setAttribute(
      'src',
      'https://accounts.google.com/gsi/client'
    );
    document.head.appendChild(googleSignInAPI);
    googleSignInAPI.onload = InitGoogleButton;
  },
});
