
import GoogleSignInButton from '@/directives/google-sign-in-button';
import config from '@/config/config';
import auth from '@/stores/auth';

export default {
  name: 'Login',
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      clientId: config.googleClientId,
    };
  },
  methods: {
    // eslint-disable-next-line
    onGoogleAuthSuccess(jwtCredentials: string) {
      const profileData = JSON.parse(atob(jwtCredentials.split('.')[1]));

      const {
        name,
        email,
        given_name: givenName,
        family_name: familyName,
      } = profileData;
      auth.idToken = jwtCredentials;
      auth.email = email;
      auth.firstName = givenName;
      auth.lastName = familyName;
      auth.fullName = name;

      this.$router.push('/');
    },
  },
};
